import { useState } from "react";
import Dialog from "./Dialog";
import { Slide } from "react-awesome-reveal";
import s1 from "../assests/whychoose/1.jpg";
import s2 from "../assests/whychoose/2.jpg";
import s3 from "../assests/whychoose/3.jpg";
// import s4 from "../assests/whychoose/4.jpg";
import s5 from "../assests/whychoose/5.jpg";
// import s6 from "../assests/whychoose/6.jpg";
import s7 from "../assests/whychoose/7.jpg";
// import s8 from "../assests/whychoose/8.jpg";
import s9 from "../assests/whychoose/9.jpg";
import s10 from "../assests/whychoose/10.jpg";
const data = [
  {
    id: 1,
    image: s1,
    title: "10+ Projects",
    location: "In Lucknow",
  },
  {
    id: 2,
    image: s2,

    title: "20+ Projects",
    location: "In Kanpur",
  },
  {
    id: 3,
    image: s3,

    title: "12+ Projects",
    location: "In Varanasi",
  },
  // {
  //   id: 4,
  //   image: s4,
  //   title: "150+ Projects",
  //   location: "In Agra",
  // },
  {
    id: 5,
    image: s5,

    title: "8+ Projects",
    location: "In Saharanpur",
  },
  // {
  //   id: 6,
  //   image: s6,

  //   title: "32+ Projects",
  //   location: "In Ghaziabad",
  // },
  {
    id: 7,
    image: s7,

    title: "16+ Projects",
    location: "In Mathura",
  },
  // {
  //   id: 8,
  //   image: s8,
  //   title: "5+ Projects",
  //   location: "In Firozabad",
  // },
  {
    id: 8,
    image: s9,
    title: "5+ Projects",
    location: "In Allahabad",
  },
  {
    id: 8,
    image: s10,
    title: "5+ Projects",
    location: "In Kaushambi",
  },
];

const Project = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };
  return (
    <div className="mt-12 bg-[#142a48] py-9">
      <div className="text-center">
        <p className="text-xl lg:text-3xl font-bold pb-3 border-b-4 text-white border-yellow-400 inline-block">
          Project Completed in PAN India
        </p>
      </div>

      <div className="grid lg:grid-cols-4 sm:grid-cols-2 gap-5 max-w-7xl mx-auto px-5">
        {data.map((item) => (
          <Slide direction="left">
            <div
              key={item.id}
              className="relative slider-item w-full h-64 sm:h-80 lg:h-72 mt-10"
            >
              <img
                src={item.image}
                alt={item.title}
                className="w-full h-full object-cover rounded-md"
              />
              <div className="absolute bottom-0 left-0 w-full bg-[#28A745]  text-white text-center p-2">
                <h3 className="text-lg lg:text-xl font-bold text-orange-300">
                  {item.title}
                </h3>
                <p className="text-xs lg:text-sm">{item.location}</p>
              </div>
            </div>
          </Slide>
        ))}
      </div>
      <br />
      <br />
      <div className="flex justify-center mt-6 relative z-10">
        <button
          onClick={handleOpenDialog}
          className="px-6 py-3 bg-green-600 text-xl text-white font-medium rounded-full shadow-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
        >
          GET FREE CONSULTATION
        </button>
      </div>
      {isDialogOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <Dialog isOpen={isDialogOpen} onClose={handleCloseDialog} />
        </div>
      )}
    </div>
  );
};

export default Project;
