// WhyChoose.js
import React, { useState } from "react";
import Dialog from "./Dialog";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import { IoLocation } from "react-icons/io5";
import s11 from "../assests/11.jpg";
import s12 from "../assests/12.jpg";
import s13 from "../assests/13.jpg";
import s16 from "../assests/16.jpg";

const work = [
  {
    id: 1,
    image: s11,
    teg: "Residential",
    location: "Lucknow",
    sqft: "1560 sqft",
  },
  {
    id: 2,
    image: s12,
    teg: "Residential",
    location: "Kanpur",
    sqft: "1860 sqft",
  },
  {
    id: 3,
    image: s11,
    teg: "Residential",
    location: "Allahabad",
    sqft: "1560 sqft",
  },
  {
    id: 4,
    image: s12,
    teg: "Residential",
    location: "Varanasi",
    sqft: "1860 sqft",
  },
  {
    id: 5,
    image: s13,
    teg: "Residential",
    location: "Mirzapur",
    sqft: "2000 sqft",
  },

  {
    id: 6,
    image: s16,
    teg: "Residential",
    location: "Ghaziabad",
    sqft: "2500 sqft",
  },
];

const PreWork = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <div className="bg-[#F6F6F6] py-10 mt-32 relative">
      <div className="text-center">
        <p className=" text-xl lg:text-3xl font-bold pb-3 border-b-4 border-yellow-400 inline-block">
          Work We Have Done
        </p>
      </div>
      <br />
      <div className="max-w-7xl mx-auto px-5 relative z-10">
        <Swiper
          // install Swiper modules
          modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
          spaceBetween={30}
          slidesPerView={1}
          navigation
          pagination={{ clickable: true }}
          autoplay={{ delay: 2000 }}
          breakpoints={{
            640: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 50,
            },
          }}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log("slide change")}
        >
          {work.map((item) => (
            <SwiperSlide key={item.id} className="">
              <div className="relative">
                <img
                  src={item.image}
                  alt={item.title}
                  className="w-full h-60 object-cover rounded-md shadow-md"
                />
                <div className="bg-white rounded-md p-5 text-sm relative -top-5 z-20">
                  <p className="px-5 py-2 bg-[#00c67f] rounded-md w-fit mt-1">
                    {item.teg}
                  </p>
                  <div className="flex mt-4 gap-2 text-xl">
                    <p className="flex items-center ">
                      <IoLocation color="orange" size={22} />
                      {item.location}
                    </p>
                    |<p>{item.sqft}</p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="flex justify-center mt-6 relative z-10">
        <button
          onClick={handleOpenDialog}
          className="px-6 py-3 bg-green-600 text-xl text-white font-medium rounded-md shadow-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
        >
          CUNSULT ONLINE NOW
        </button>
      </div>
      {isDialogOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <Dialog isOpen={isDialogOpen} onClose={handleCloseDialog} />
        </div>
      )}
    </div>
  );
};

export default PreWork;
