// WhyChoose.js
import React, { useState } from "react";
import Dialog from "./Dialog"; // Import the Dialog component

const data = [
  {
    id: 1,
    image:
      "https://www.makemyhouse.com/assets/ilpg/assets/lp/images/choose1.png",
    title: "100% Quality Assurance",
    subTitle:
      "We assured Design Satisfaction and assistance till the end of your project",
  },
  {
    id: 2,
    image:
      "https://www.makemyhouse.com/assets/ilpg/assets/lp/images/choose2.png",
    title: "1 Day Delivery",
    subTitle:
      "We assure to provide Floor Plan within 24hrs of project created.",
  },
  {
    id: 3,
    image:
      "https://www.makemyhouse.com/assets/ilpg/assets/lp/images/choose3.png",
    title: "Project Trackability",
    subTitle: "Get timely update of your project on our application.",
  },
  {
    id: 4,
    image:
      "https://www.makemyhouse.com/assets/ilpg/assets/lp/images/choose4.png",
    title: "Dedicated Support team",
    subTitle: "8x6 dedicated support team on project chat, call and Mail.",
  },
  {
    id: 5,
    image:
      "https://www.makemyhouse.com/assets/ilpg/assets/lp/images/moenyback.png",
    title: "100% Money Back Guarantee",
    subTitle:
      "We provide 100% written Money back guarantee on designs if not satisfied.",
  },
];

const WhyChoose = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <div className="bg-[#F6F6F6] py-10 mt-10">
      <p className="text-xl lg:text-3xl flex m-auto pb-3 font-bold border-b-4 border-b-yellow-400 w-fit">
        Why Choose Us
      </p>
      <div className="main grid lg:grid-cols-5 gap-5  rounded-md p-5">
        {data.map((currElem) => (
          <div
            key={currElem.id}
            className="card p-4 shadow-lg rounded-md bg-white-100"
          >
            <img
              src={currElem.image}
              alt="not found"
              className="w-full h-32 object-contain mb-4"
            />
            <p className="text-lg font-bold mb-2">{currElem.title}</p>
            <p className="text-sm text-gray-700">{currElem.subTitle}</p>
          </div>
        ))}
      </div>
      <div className="flex justify-center mt-6">
        <button
          onClick={handleOpenDialog}
          className="w-fit px-4 bg-[#28A745] text-xl py-3 text-white font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          GET FREE CONSULTATION
        </button>
      </div>
      <Dialog isOpen={isDialogOpen} onClose={handleCloseDialog} />
    </div>
  );
};

export default WhyChoose;
