import React from "react";
import logo from "../assests/logo.png";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
  FaYoutube,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { MdEmail, MdLocationCity } from "react-icons/md";

const Footer = () => {
  return (
    <div className="bg-[#142a48] py-10">
      <div className="main pl-2 flex flex-col gap-6 lg:flex-row lg:justify-between lg:items-center max-w-7xl mx-auto px-5">
        <div className="first flex flex-col ">
          <div className="w-48 h-24 lg:w-40 pl-6 lg:h-28">
            <img
              src={logo}
              className="w-full h-full object-contain  bg-white"
              alt="Logo"
            />
          </div>
          <br />

          <p className="text-sm text-white  pl-6 lg:pl-2  lg:mt-0 lg:ml-4">
            Made by I NEXT ETS
          </p>
        </div>
        <div className="second flex flex-col items-center lg:flex-row lg:items-center text-white text-xl lg:justify-end">
          <div className="lg:ml-8 mb-4 lg:mb-0">
            <p className="mb-2">T&C | Privacy Policy | Disclaimer</p>
            <div className="flex items-center gap-2 my-6">
              <p className="w-10 h-10 flex cursor-pointer items-center justify-center rounded-full bg-green-600">
                <MdEmail />
              </p>
              <p>Theengineerworkers@gmail.com</p>
            </div>
            <div className="flex items-center gap-2 my-6">
              <p className="w-10 h-10 flex cursor-pointer items-center justify-center rounded-full bg-green-600">
                <MdLocationCity />
              </p>
              <p>Lucknow, Uttar Pradesh, India</p>
            </div>
            <div className="flex gap-5">
              <Link
                to="https://www.facebook.com/shuklasibbhu"
                className="w-10 h-10 flex cursor-pointer items-center justify-center rounded-full bg-green-600"
                target="_blank"
              >
                <FaFacebookF />
              </Link>
              <p
                className="w-10 h-10 cursor-pointer flex items-center justify-center rounded-full bg-green-600"
                target="_blank"
              >
                <FaYoutube />
              </p>
              <Link
                className="w-10 h-10 flex items-center justify-center rounded-full cursor-pointer bg-green-600"
                target="_blank"
              >
                <FaInstagram />
              </Link>
              <Link
                to="https://www.linkedin.com/in/the-engineer-workers-5161b8318/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app%20Always%20o"
                className="w-10 h-10 flex items-center justify-center cursor-pointer rounded-full bg-green-600"
                target="_blank"
              >
                <FaLinkedin />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
