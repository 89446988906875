import React from "react";
import { Fade } from "react-awesome-reveal";

const About = () => {
  return (
    <div>
      <div className="relative w-full h-[500px] ">
        <img
          src="https://res.infoq.com/articles/who-is-on-the-team/en/headerimage/who-is-on-the-team-header-1612952290708.jpg"
          alt="not found"
          className="w-full h-full object-cover"
        />
        <p className="absolute inset-0 flex items-center justify-center text-white text-xl lg:text-3xl font-bold bg-black bg-opacity-50">
          About Us
        </p>
      </div>
      <Fade cascade damping={0.1}>
        <div className="max-w-7xl mx-auto px-4 mt-5 text-sm grid gap-4 leading-6">
          <p>
            The Engineer workers is the India's growing Online Architectural
            Design Platform for the past 4 years . The staff has been guided by
            the group of directors who has 4+ years of wise experience. we have
            over 30+ dedicated employees serving clients across the country.
          </p>
          <p>
            Together, we discover ideas and connect the dots to build a better
            and a bold new future. Using an evolving collection of proven online
            marketing tactics , we provide customized Floor plans,3D
            Elevation,Interior designs,Technical drawings & Architectural
            guidance .
          </p>
          <p>
            Our passion is to connect our clients to the very best of our
            projects. 30+ best and professional Architects,determined to give
            you world class designs. 300+ clients in 10+ cities with 95%
            customer satisfaction.
          </p>
          <p className="text-xl text-orange-500">
            We are providing various services.
          </p>
          <ul className="list-disc list-inside space-y-2 text-base lg:text-sm">
            <li>Planning and Designing (2D, 3D)</li>
            <li>Estimate and Costing</li>
            <li>Structure Designing</li>
            <li>Interior Designing</li>
            <li>Constructions</li>
            <li>Vastu Consultant with Expert</li>
          </ul>
        </div>
      </Fade>
    </div>
  );
};

export default About;
