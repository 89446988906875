// Dialog.js
import React from "react";
import Contact from "./Contact"; // Import the Contact component

const Dialog = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="p-8 rounded-md shadow-md relative max-w-lg w-full">
        <button
          onClick={onClose}
          className="absolute text-5xl top-16 right-9 text-white hover:text-gray-200"
        >
          &times;
        </button>
        <Contact /> {/* Include the Contact form here */}
      </div>
    </div>
  );
};

export default Dialog;
