import React from "react";
import Contact from "./Contact";
import { Slide } from "react-awesome-reveal";

const Hero = () => {
  return (
    <div
      className="bg-cover bg-center bg-no-repeat "
      style={{
        opacity: "revert-layer",
        backgroundImage: `url('https://i.ibb.co/LCNXB1b/home.png')`,
      }}
    >
      <div className="main grid lg:grid-cols-2 max-w-7xl mx-auto px-4 py-1 bg-opacity-50">
        <Slide direction="left">
          <div className="first grid gap-4 p-4 md:gap-6 md:p-8 text-white">
            <p className="text-3xl md:text-5xl font-semibold w-full md:w-96 border-l-4 border-orange-500 pl-4">
              Hi👋Let's know about Architectural Platform
            </p>
            <p className="text-xl md:text-2xl font-bold">
              Engineer workers Build Your Dreams
            </p>
            <p className="text-xl md:text-2xl font-bold">
              India's growing Online Architectural Design Platform from 2020
            </p>
            <ul className="list-disc list-inside space-y-2 text-base md:text-lg">
              <li>
                Engineers Workers is Platform where we Serve a Design of your
                Dreams.
              </li>
              <li>Which you are Planning in the soil of Earth</li>
              <li>We Plan your Thoughts Construct your Dream.</li>
              <li>
                Execute your Desires with Our Educated Engineers and Architect
              </li>
            </ul>

            <div>
              <p className="bg-zinc-50 opacity-75 bg-opacity-90 rounded-md text-black w-fit px-5 py-5 text-xl lg:text-2xl">
                Successfully Completed{" "}
                <span className="text-orange-500">500+</span> Projects
              </p>
            </div>
          </div>
        </Slide>

        <Slide direction="right">
          <div className="second">
            <Contact />
          </div>
        </Slide>
      </div>
    </div>
  );
};

export default Hero;
