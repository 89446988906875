import React from 'react'
import Navbar from './components/Navbar'
import Hero from './components/Hero'
import About from './components/About'
import WhyChoose from './components/WhyChoose'
import Services from './components/Services'
import PreWork from './components/PreWork'
import Project from './components/Project'
import Faq from './components/Faq'
import Footer from './components/Footer'

const App = () => {
  return (
    <div className=''>
      <Navbar />
      <Hero />
      <About />
      <WhyChoose />
      <Services />
      <PreWork />
      <Project />
      <Faq />
      <Footer />
    </div>
  )
}

export default App