import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import s1 from "../assests/1.jpg";
import s2 from "../assests/img/6.jpg";
import s3 from "../assests/img/3.jpg";
import s4 from "../assests/img/4.jpg";
import s5 from "../assests/9.jpg";
import s6 from "../assests/img/2.jpg";
// import s7 from "../assests/cement.png";
// import s8 from "../assests/8.jpg";
// import s9 from "../assests/9.jpg";
// import s10 from "../assests/10.jpg";
import s17 from "../assests/16.jpg";
import s18 from "../assests/img/5.jpg";
// import s19 from "../assests/12.jpg";
import s20 from "../assests/img/1.jpg";
import s22 from "../assests/img/7.jpg";
import s23 from "../assests/img/10.jpg";

const data = [
  {
    id: 1,
    image: s1,
    title: "Planning and Designing (2D,3D)",
  },
  {
    id: 2,
    image: s2,
    title: "Estimate and Costing",
  },
  {
    id: 3,
    image: s3,
    title: "Structure Designing",
  },
  {
    id: 4,
    image: s4,
    title: "Interior Designing",
  },
  {
    id: 5,
    image: s5,
    title: "Constructions",
  },
  {
    id: 6,
    image: s6,
    title: "Vastu Consultant with Expert",
  },
  // {
  //   id: 7,
  //   image: s7,
  //   title: "Cement ,Sand ,Aggregate",
  // },
  // {
  //   id: 8,
  //   image: s8,
  //   title: "Steel ,Brick,Plyboard,Pipe.",
  // },
  // {
  //   id: 9,
  //   image: s9,
  //   title: "Paints,Putty,White Cement,Chemicals",
  // },
  // {
  //   id: 10,
  //   image: s10,
  //   title: "Bathware,Sanitary",
  // },
  // {
  //   id: 17,
  //   image: s7,
  //   title: "Marking of Plan at Site with Expert Architect.",
  // },
  {
    id: 18,
    image: s18,
    title: "Execution of Foundation and Anti Termite Treatment by Expert.",
  },

  {
    id: 19,
    image: s23,
    title: "Checking of Cement ,Sand and Aggregate by Engineers.",
  },
  {
    id: 20,
    image: s20,
    title: "Slab Supervision and Concrete Check by Engineers.",
  },
  {
    id: 21,
    image: s17,
    title:
      "Interiors Works ,Wooden Works, Kitchen work in Guidance by Interior Designer.",
  },
  {
    id: 22,
    image: s22,
    title: "Electrical Works will be done by Electrical Engineers.",
  },
  {
    id: 23,
    image: s23,
    title: "Concrete Test.",
  },
];

const Slider = () => {
  return (
    <div className="px-5">
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
        spaceBetween={30}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        autoplay={{ delay: 2000 }}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 50,
          },
        }}
        onSwiper={(swiper) => console.log(swiper)}
        onSlideChange={() => console.log("slide change")}
      >
        {data.map((item) => (
          <SwiperSlide key={item.id}>
            <div className="relative slider-item w-full h-[500px] mt-10">
              <img
                src={item.image}
                alt={item.title}
                className="w-full h-full object-cover"
              />
              <h3 className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-60 text-white text-center text-xl py-10 p-2">
                {item.title}
              </h3>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Slider;
