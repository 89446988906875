import React from "react";
import Slider from "./Slider";

const Services = () => {
  return (
    <div className="mt-16">
      <div>
        <p className="text-xl lg:text-3xl flex m-auto pb-3 font-bold border-b-4 border-b-yellow-400 w-fit">
          Services
        </p>
        <Slider />
      </div>
    </div>
  );
};

export default Services;
