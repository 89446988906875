import React, { useState } from "react";
import emailjs from "emailjs-com";

const stateCityData = {
  UttarPradesh: [
    "Lucknow",
    "Kanpur",
    "Varanasi",
    "Agra",
    "Meerut",
    "Prayagraj",
    "Bareilly",
    "Aligarh",
    "Moradabad",
    "Saharanpur",
    "Gorakhpur",
    "Noida",
    "Ghaziabad",
    "Jhansi",
    "Muzaffarnagar",
    "Mathura",
    "Rampur",
    "Firozabad",
    "Shahjahanpur",
    "Faizabad",
    "Raebareli",
    "Sitapur",
    "Mirzapur",
    "Unnao",
    "Farrukhabad",
    "Etawah",
    "Budaun",
    "Etah",
    "Deoria",
    "Hathras",
    "Mainpuri",
    "Lakhimpur",
    "Jaunpur",
    "Ballia",
    "Azamgarh",
    "Mau",
    "Banda",
    "Hamirpur",
    "Other",
  ],

  // Add more states and cities as needed
};

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
    state: "",
    city: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleStateChange = (e) => {
    const selectedState = e.target.value;
    setFormData({ ...formData, state: selectedState, city: "" });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        "service_ahifqa8",
        "template_gs8ogdn",
        formData,
        "q9U-M8248MRhwfK59"
      )
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
        alert("Message sent successfully!");
        setFormData({
          name: "",
          phone: "",
          email: "",
          message: "",
          state: "",
          city: "",
        });
      })
      .catch((err) => {
        console.error("FAILED...", err);
        alert("Failed to send message. Please try again later.");
      });
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="max-w-md mx-auto py-9 px-12 bg-[#12203c] rounded shadow mt-10"
    >
      <div className="text-white border-l-2 border-l-white pl-2">
        <p className="text-3xl font-bold">Contact US</p>
        <p className="text-sm my-2">
          Kindly fill the form,Our team will get back to you.
        </p>
      </div>
      <br />
      <div className="mb-4">
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
          placeholder="Name"
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>
      <div className="mb-4">
        <input
          type="text"
          id="phone"
          name="phone"
          placeholder="Phone Number"
          value={formData.phone}
          onChange={handleChange}
          required
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>
      <div className="mb-4">
        <input
          type="email"
          id="email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleChange}
          required
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>
      <div className="mb-4">
        <select
          id="state"
          name="state"
          value={formData.state}
          onChange={handleStateChange}
          required
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        >
          <option value="">Select State</option>
          {Object.keys(stateCityData).map((state) => (
            <option key={state} value={state}>
              {state}
            </option>
          ))}
        </select>
      </div>
      <div className="mb-4">
        <select
          id="city"
          name="city"
          value={formData.city}
          onChange={handleChange}
          required
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          disabled={!formData.state}
        >
          <option value="">Select City</option>
          {formData.state &&
            stateCityData[formData.state].map((city) => (
              <option key={city} value={city}>
                {city}
              </option>
            ))}
        </select>
      </div>
      <div className="mb-4">
        <textarea
          id="message"
          name="message"
          placeholder="Requirement"
          value={formData.message}
          onChange={handleChange}
          required
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>
      <button
        type="submit"
        className="w-full px-4 bg-[#28A745] text-xl py-3 text-white font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Submit
      </button>
    </form>
  );
};

export default Contact;
