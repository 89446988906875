import React from "react";
import logo from "../assests/logo.png";
import { FaPhoneVolume } from "react-icons/fa";

const Navbar = () => {
  return (
    <div className="py-2 bg-gray-100">
      <div className="flex justify-between items-center max-w-7xl mx-auto px-4 md:px-8">
        <div className="w-32 h-24 md:w-40 md:h-28">
          <img src={logo} className="w-full h-full object-cover" alt="Logo" />
        </div>
        <div className="flex items-center gap-2 md:gap-3">
          <p className="border-r-2 pr-2  border-gray-300">
            <FaPhoneVolume size={28} className="text-gray-700" />
          </p>
          <p className="text-xs md:text-sm text-gray-700">+91 84005 23984</p>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
