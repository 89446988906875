import { useState } from "react";
import Dialog from "./Dialog";

const Faq = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <div className="bg-[#F6F6F6] py-8 sm:py-16">
      <div className="flex flex-col lg:flex-row lg:justify-between  max-w-7xl mx-auto px-5 lg:text-start text-center">
        <div className="text-lg sm:text-2xl font-bold mb-4">
          If it's Residential Architecture
          <br />
          <span className="text-orange-400">It's Us</span>
        </div>
        <div className="flex justify-center">
          <button
            onClick={handleOpenDialog}
            className="px-6 bg-green-500 h-14 rounded-full text-xl text-white"
          >
            GIVE US A CHANCE TO SERVE YOU
          </button>
        </div>
        {isDialogOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <Dialog isOpen={isDialogOpen} onClose={handleCloseDialog} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Faq;
